<template>
    <div>
      <div class="task-nav">
        <div style="flex: 1">
          <a-row :gutter="24">
            <a-form-model :label-col="{ span: 6 }" :wrapper-col="{ span: 16 }" class="ant-advanced-search-form">
              <a-col :span="7">
                <a-form-model-item label="规划科目">
                    <a-select allowClear placeholder="请选择" v-model="subjectSearch">
                      <a-select-option :value="item.value" v-for="(item, index) in subjectArr" :key="index">
                        {{ item.label }}
                      </a-select-option>
                    </a-select>
                </a-form-model-item>
              </a-col>
              <a-col :span="7">
                <a-form-model-item label="操作人">
                 <a-input v-model="ownerSearch" placeholder="请输入" />
                </a-form-model-item>
              </a-col>
              <a-col :span="8">
                <a-form-model-item label="生成时间">
                  <a-range-picker
                           show-time
                            @change="onChangeTableDate"
                            v-model="queryClassDateArr"
                         :format="dateFormat"
                           
                          />
                </a-form-model-item>
              </a-col>           
            </a-form-model>
          </a-row>
        </div>
        <div class="button-box">
          <a-button type="primary" style="margin-right: 10px" @click="initSearchData('search')">查询</a-button>
          <a-button style="margin-right: 10px" @click="resetCondition">重置</a-button>
        </div>
      </div>
      <div class="table-wrap">
         <a-table
         :loading="loading"
        :columns="columns"
        :data-source="tableData"
        @change="handleTableChange"
        :pagination="initPagination"
        :rowKey="
          (record, index) => {
            return index;
          }
        "
      >
      
        <template slot="subject" slot-scope="subject">
        
         
          <span>
            {{subject === 'CHINESE' ? '中文' : '数学' }}
          </span>
        </template>
        <template slot="imageUrl" slot-scope="url,record">
          <img :src="url" style="width:50px;height:50px;cursor:pointer"   :preview="url">
        </template>
       <template slot="operation" slot-scope="operation, record">
        <span class="operation" @click="copyUrl(record.url)">复制链接</span>
      </template>
      </a-table>
      </div>
    
        <a-drawer class="test-main" title="审批详情"  :body-style="{ paddingBottom: '80px' }" placement="right" width="700" :visible="visible" @close="onClose">
          <div class="task-info" v-if="formInfoData">
            <div class="task-form">
              <p class="form-name" style="margin-bottom:12px;">
              分享赠课
                <span class="pending-status" v-if="formInfoData.approveStatus==='待审核'">待审核</span>
                <span class="success-status" v-if="formInfoData.approveStatus==='已批准'">已批准</span>
                <span class="refuse-status" v-if="formInfoData.approveStatus==='已拒绝'">已拒绝</span>
              </p>
              <div class="form-teacher">
                <img :src="defaultImg" alt="" />
                <span
                  >提交人：{{ formInfoData.whoCreated}}</span
                >
               |
                <span>提交时间：{{formInfoData.whenCreated}}</span>
               
              
              </div>
          
              
            </div>
             <div class="line-wrap">
               <InfoTitle title="关联学生" />
              <div class="student-wrap">
                <div class="student-item" >
                  <img :src="defaultImg" alt="" class="handler-img" />
                  <div>
                    <p class="student-name cursor" @click="hrefStudent">
                      {{ studentProfile.fullName }} ({{ studentProfile.code }})>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="apply-detail">
              <InfoTitle title="申请详情" />
              <div class="apply-detailContent">
                <div class="apply-item"><span class="apply-label">科目：</span><span class="apply-content">{{formInfoData.courseSubject==='CHINESE'?'中文':'数学'}}</span></div>
               <div class="apply-item"><span class="apply-label">来源：</span><span class="apply-content">{{initOptionsType(formInfoData.source,originStatusArr)}}</span></div>
               <div class="apply-item"><span class="apply-label">上传图片：</span><span class="apply-content"><img v-if="formInfoData.imageUrl" style="width:40px;height:40px;" :src="formInfoData.imageUrl"  preview="1" alt="" /></span></div>
              <div class="apply-item"><span class="apply-label">赠课数量：</span><span class="apply-content">{{formInfoData.approveCredit}}</span></div>
               <div class="apply-item"><span class="apply-label">备注：</span><span class="apply-content">{{formInfoData.remark}}</span></div>
              </div>
            </div>
             <div class="apply-detail">
              <InfoTitle title="审批结果" />
              <div class="apply-detailContent">
              
                   <a-timeline style="margin-top:16px;">
                    <a-timeline-item>
                       <div class="apply-item"><span class="apply-label">审批结果：</span><span class="apply-content">{{formInfoData.approveStatus}}</span></div>
                       <div class="apply-item" v-if="formInfoData.approveRemarks"><span class="apply-label">拒绝原因：</span><span class="apply-content">{{formInfoData.approveRemarks}}</span></div>
                    </a-timeline-item>
                 
                  </a-timeline>
              </div>
            </div>
         
          </div>
        </a-drawer>
     
      
    </div>
</template>

<script>
import InfoTitle from '@/components/WkInfoTitle/InfoTitle';
import defaultImg from '@/assets/service_pic_head.png';
import moment from 'moment';
import storage from 'store';
import sessionStorage from 'store/storages/sessionStorage';

const session = storage.createStore(sessionStorage);
import {
  queryPlanHistory,
getHeadmasterList,
submitStatusApproval,
queryCourseSchedule
 
} from '@/api/headTeacher';
export default {
  name:'learningPlanningHistory',
   components: { InfoTitle },
  data(){
    return{
      dateFormat: 'YYYY-MM-DD HH:mm:ss',
       queryStartTime:'',
       queryEndTime:'',
     queryClassDateArr:[],
     studentProfile: session.get('studentProfile'),
      ownerSearch:undefined,
   
      subjectSearch:undefined,
       subjectArr: [
        { label: '中文', value: 'CHINESE' },
        { label: '数学', value: 'MATH' },
         { label: '英文', value: 'ENGLISH' },
      ],
       statusTypeArr: [
        { label: '待审批', value: 'PENDING' },
        { label: '已批准', value: 'PASSED' },
       { label: '已拒绝', value: 'REJECTED' },
      ],
      originStatusArr:[
        {label:'CRM',value:'CRM'},
         {label:'花果',value:'CLASS_ADMIN_STUDIO'},
         {label:'学员中心APP',value:'STUDENT_CENTER_APP'},
         {label:'学员中心小程序',value:'SMALL_PROGRAM'}
      ],
      searchName:'',
      userInfo:{},
      courseScheduleObj:{},
       tableSorter: {},
      remarkTC:'',
      examineTitle:'',
      visibleResult:false,
      actualData:{},
      visibleExamine:false,
      defaultImg,
      formInfoData:{},
      selType:'PENDING',
      headmasterList:[],
      submitterId:undefined,
      transactionCategory:undefined,
      transactionCategoryArr:[
        {label:'冻结课程',value:'冻结课程'},
         {label:'延期开课',value:'延期开课'},
      
      ],
      approveStatus:undefined,
      approveStatusArr:[
        {label:'待审核',value:'PENDING'},
        {label:'已通过',value:'PASSED'},
        {label:'已拒绝',value:'REJECTED'},
      ],
      visible:false,
      pageNum: 1,
       selTabData: [
        { label: '我提交', value: 'OWN' },
        { label: '待审批', value: 'PENDING' },
        { label: '已审批', value: 'PASSED' },
      
      ],
    
        columns: [
        {
          title: '序号',
          key: 'index',
          width: 60,
          fixed: 'left',
          dataIndex: 'num',
          customRender: (text, record, index) => `${(this.current - 1) * this.pageSize + (index + 1)}`,
        },
          {
          title: '学习规划',
          dataIndex: 'url',
          key: 'url',
          scopedSlots: { customRender: 'imageUrl' },
        },
          {
          title: '科目',
          dataIndex: 'subject',
          key: 'subject',
          scopedSlots: { customRender: 'subject' },
          
        },
       
          {
          title: '操作人',
          dataIndex: 'owner',
          key: 'owner',
       
        },
        {
          title: '生成时间',
          dataIndex: 'whenCreated',
          key: 'whenCreated',
         
        },
      
        {
          title: '操作',
          dataIndex: 'operation',
          with: 120,
          fixed: 'right',
          scopedSlots: { customRender: 'operation' },
        },
      ],
      tableData:[],
      current: 0,
      pageSize: 10,
      initPagination: {
        total: 0,
        current: 1,
        pageSize: 10,
      },

      loading: false,
    }
  },
  methods:{
    moment,
    copyUrl(url){

      let transUrl=`${this.transUrlToCDN(url)}?response-content-type=image/png`
         const input = document.createElement('input');
      input.value =transUrl;
      document.body.appendChild(input);
      input.select();
      document.execCommand('Copy');
      document.body.removeChild(input);
      this.$message.success("已复制链接")
    },
    transUrlToCDN(value){
  const [, content] = value.split('//');
  const [, ...paths] = content.split('/');
  return `${[process.env.VUE_APP_CDN_HOST, ...paths].join('/')}`;
},
     onChangeTableDate(date){
      if (date.length > 0) {
        this.queryStartTime = date[0].format('YYYY-MM-DD HH:mm:ss');
        this.queryEndTime = date[1].format('YYYY-MM-DD HH:mm:ss');
      } else {
        this.queryStartTime = null;
        this.queryEndTime = null;
      }
  },
   
      filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.indexOf(input) >= 0
      );
    },
      playback(){
     
       window.open(this.courseScheduleObj.liveUrl,'_blank')
    },
    
    // 查询课节
    queryCourseSchedule(uuid) {
      const params = {
        uuid,
      };

      queryCourseSchedule(params).then((res) => {
        this.courseScheduleObj=res.data.content
        
      })
    },
    cancelResult(){
      this.visibleResult=false
       this.cancelExamine()
         this.visible=false;
         this.initSearchData()

    },
    examineFun(val){
      this.approveStatusTC=val
      if(val==='PASSED'){
        this.examineTitle="审核通过"
      
      }else{
         this.examineTitle="审核不通过"
      }
       this.visibleExamine=true
    },
    cancelExamine(){
      this.visibleExamine=false
      this.remarkTC="";
    },
    saveExamine(){
      let params={
        reviewId:this.formInfoData.uuid,
        approveStatus:this.approveStatusTC,
        remark:this.remarkTC
      }
      submitStatusApproval(params).then(res=>{
     
          this.$message.success('提交成功')
          this.cancelExamine()
            this.visible=false
            this.initSearchData()
        
      })
    },
    hrefStudent(){
        const routeUrl = this.$router.resolve({
        path: '/studentDetail',
        query: { uuid:this.formInfoData.studentId },
      });
      window.open(routeUrl.href, '_blank');
    },
   initOptionsType(value, typeArr) {
      let name = '';
      typeArr?.forEach?.((item) => {
        if (item.value === value) {
          name = item.label;
        }
      });
      return name;
    },
 
    onClose(){
        this.visible=false
    },
   
    onSearchReport(){

    },
   
    searchHeadmaster(name) {
      const params = {
        roles:["ADMIN", "SUPER_ADMIN","X_WUKONG","HEAD_TEACHER_LEADER","HEAD_TEACHER_SUPER","CLASS_ADMIN","TEACHING_STAFF","TEACHING_STAFF_ADMIN"],
        name,
      };
      getHeadmasterList(params).then((res) => {
        console.log(res)
        this.headmasterList = res.data.content;
        console.log(this.headmasterList)
      });
    },
      handleTableChange(data,filters, sorter) {
           this.tableSorter = sorter;
       
      this.pageNum = data.current;
      this.initPagination.current = data.current;
     
      this.initSearchData();
    },
    
    resetCondition(){
        this.subjectSearch=undefined;
        this.ownerSearch=undefined;
        this.queryClassDateArr=[];
         this.queryStartTime = null;
        this.queryEndTime = null;
        this.pageNum=1;
        this.pageSize=10;
        this.initSearchData()
    },
    initSearchData(act) {
      if (act === 'search') {
        this.pageNum = 1;
      }
      const params = {
        owner:this.ownerSearch,
        whenCreatedFrom:this.queryStartTime,
        whenCreatedTo:this.queryEndTime,
        subject:this.subjectSearch,
        pageSize:this.pageSize,
        studentId:this.studentProfile.uuid
      };
    
        this.loading=true
      queryPlanHistory(this.pageNum,params).then((res) => {
        this.tableData = res.data.content;
        this.current = res.data.currentPage;
        this.pageSize = res.data.pageSize;
       this.initPagination.current=res.data.currentPage;
        this.initPagination.total = res.data.totalCount;
        this.initPagination.pageSize=res.data.pageSize;
        this.loading = false;
      });
    },
  },
  created(){
      this.userInfo = storage.get('userInfo');
      if (this.userInfo.roleName === 'CLASS_ADMIN'){
        this.selType="OWN"
      }
  },
  mounted(){
    this.initSearchData()
  }
}

</script>

<style lang="less" scoped> 
.operation{
   color: #3372FE;
   cursor: pointer;
}
.task-nav {
  background-color: #fff;
  margin-bottom: 8px;
  padding: 20px 0 0;
  display: flex;
  justify-content: space-between;
}
.ant-advanced-search-form {
  /deep/ .ant-col-16 {
    width: 70%;
  }

}
.table-wrap {
  padding: 20px;
  background: #fff;
      min-height: calc(100vh - 200px);

}
.sel-navwrap {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}
.task-info {
 
  .task-type {
    font-size: 14px;
    font-weight: 400;
    color: #333333;
    line-height: 20px;
    margin-bottom: 20px;
    span {
      padding: 2px 10px;
      background-color: #f7f7f7;
      border-radius: 6px;
      margin-right: 10px;
    }
  }
  .task-form {
    border-bottom: 1px solid #eee;
    .form-name {
      font-size: 20px;
      font-weight: 600;
      color: #333333;
      line-height: 28px;
      span {
        font-size: 14px;
        font-weight: 600;
        line-height: 20px;
        padding: 2px 10px;
        border-radius: 6px;
        margin: 0 10px;
      }
    }
    .pending-status {
      color: #ffbf00;
      background-color: rgba(255, 191, 0, 0.09);
    }
    .success-status {
      color: #00cf64;
      background-color: rgba(0, 207, 100, 0.09);
    }
    .refuse-status {
      color: #ff5353;
      background-color: rgba(255, 83, 83, 0.09);
    }
    .form-teacher {
      font-size: 16px;
      font-weight: 400;
      color: #999;
      line-height: 22px;
      margin-bottom: 20px;
      .dueDate {
        color: #ff5454;
      }
      span {
        color: #333;
      }
      img {
        width: 18px;
        height: 18px;
        border-radius: 50%;
        margin-right: 10px;
      }
    }
    .form-des {
      font-size: 16px;
      font-weight: 400;
      color: #333333;
      line-height: 22px;
    }
    .form-imgs {
      img {
        width: 80px;
        height: 80px;
        border-radius: 4px;
        margin-right: 10px;
      }
      margin-bottom: 20px;
    }
  }
  .courseInfo {
    padding: 20px 0;
    .course-info {
      padding: 20px 16px;
      background: linear-gradient(180deg, #ecf7ff 0%, #f7fcff 100%);
      border-radius: 20px;
      .course-name {
        font-size: 16px;
        font-weight: 600;
        color: #009cff;
        line-height: 22px;
        margin-bottom: 10px;
        &:hover {
          text-decoration: underline;
        }
      }
      .course-type {
        font-size: 14px;
        font-weight: 400;
        color: #999999;
        line-height: 18px;
        margin-top: 10px;
      }
    }
  }
  .taskFormBtn {
    position: fixed;
    right: 0;
    bottom: 0;
    width: 700px;
    border-top: 1px solid #e9e9e9;
    background-color: #fff;
    padding: 10px 16px;
    background: '#fff';
    text-align: right;
    z-index: 1;
    img {
      margin: 0 10px 0 0;
    }
    .ant-btn-primary {
     // background-color: #009cff;
    }
  }
}
.apply-item{
  margin-bottom: 10px;
  display: flex;
}
.apply-label{
      color: rgba(31, 45, 61, 0.4);
          min-width: 43px;
}
.apply-content{
  color: #1f2d3d;
  flex:1;
}
.student-item {
  display: flex;
  justify-content: flex-start;
  margin-right: 20px;
}
.student-item p {
  font-size: 14px;
  font-weight: 400;
  color: #999999;
  line-height: 20px;
}
.student-item img {
  width: 42px;
  height: 42px;
  border-radius: 50%;
  margin-right: 7px;
}
.student-item .student-name {
  font-size: 16px;
  font-weight: 600;
 
  line-height: 22px;
}
.student-wrap {
  display: flex;
  margin-bottom: 22px;
}
.cursor {
  cursor: pointer;
  color: #43d186!important;
}
.line-wrap{
  margin-top: 20px;
 
  border-bottom: 1px solid #eee;
}
.apply-detail{
  margin-top: 20px;
}
.examine-item{
  margin-bottom: 14px;
   display: flex;
}
.examine-label{
  width:80px;
}
.examine-content{
  flex:1
}
.trans-wrap{
 background: rgba(0,0,0,.02);
  height:280px;
  display: flex;
}
.trans-itemWrap{
  flex: 1;
  padding:10px 20px;
}
.trans-item{
  margin-top:5px;
}
.trans-itemLabel{

}
</style>